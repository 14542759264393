import { useTranslation } from 'react-i18next'

export function NotFoundTexts() {
  const { t } = useTranslation()

  return {
    title: t('pNotFound.title'),
    description: t('pNotFound.description'),
    goBack: t('pNotFound.goBack')
  }
}
