import { NextSeo } from 'next-seo'
import Image from 'next/image'

import { Button } from 'components/Button'

import { defaultSEO } from 'helpers/defaultSEO'

import styles from './NotFound.module.scss'
import { NotFoundTexts } from './NotFound.texts'

export default function NotFound() {
  const texts = NotFoundTexts()

  return (
    <>
      <NextSeo
        {...defaultSEO}
        title={`${texts.title} | Top Servers`}
        description={texts.description}
        nofollow
        noindex
      />
      <div className={`${styles.wrapper} container`}>
        <div className={styles.logo}>
          <Image
            src="/assets/logo/bg-dark.svg"
            alt="TS"
            title="Top Servers"
            layout="fill"
          />
        </div>
        <div>
          <h1>404</h1>
          <h5>{texts.title}</h5>
          <p className="col-7">{texts.description}</p>
        </div>
        <Button as="link" href="/">
          {texts.goBack}
        </Button>
      </div>
    </>
  )
}
